
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import base from "@/api/base.js";
import * as Yup from "yup";

export default defineComponent({
  name: "account-settings",

  components: {
    ErrorMessage,
    Field,
    Form,
  },

  data() {
    const validation = Yup.object().shape({
      brand: Yup.string().required("필수값입니다."),
      qscItemName: Yup.string().required("필수값입니다."),
      item: Yup.string().required("필수값입니다."),
      mainItem: Yup.string().required("필수값입니다."),
      middleItem: Yup.string().required("필수값입니다."),
      subItem: Yup.string().required("필수값입니다."),
      itemTypeRequired: Yup.string().required("필수값입니다."),
      itemTypeUse: Yup.string().required("필수값입니다."),
      // dropName: Yup.string().required("필수값입니다."),
      // subjectiveRequireMinimum: Yup.string()
      //   .matches(/^\d{1,3}$/, "세자리 이하의 숫자로만 입력 가능합니다. ex)10")
      //   .required("필수값입니다."),
      maxScore: Yup.string()
        .matches(/^\d{1,3}$/, "세자리 이하의 숫자로만 입력 가능합니다. ex)10")
        .required("필수값입니다."),
      // dropPoint: Yup.string()
      //   .matches(/^\d{1,3}$/, "세자리 이하의 숫자로만 입력 가능합니다. ex)10")
      //   .required("필수값입니다."),
    });

    return {
      checkItem: { detail: [] },
      checkItems: [],
      qscList: [],
      brands: [],
      additemexmp: "",
      additemscore: 0,
      largectgry: [],
      middlectgry: [],
      smallctgry: [],
      validation,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {};
  },
  mounted() {
    base
      .getCheckItemList()
      .then((res) => {
        console.log(res);
        this.checkItems = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getBrandList()
      .then((res) => {
        console.log(res);
        this.brands = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    async submitForm() {
      this.checkItem.detail = this.qscList;

      console.log(this.checkItem);
      await base
        .newCheckItem(this.checkItem)
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            alert("신규 점검 항목을 등록했습니다.");
            this.$router.push("/menu/check-items/items");
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    addtextlength() {
      this.qscList.push({
        text_min_length: this.text_min_length,
      });
    },
    addQsc() {
      console.log(this.qscList);
      this.qscList.push({
        item_exmp: this.additemexmp,
        item_score: this.additemscore,
      });
    },
    removeQsc(index) {
      this.qscList.splice(index, 1);
    },
    getLargeCategory() {
      const data = {
        ctgry_ty: "QSCCT100100",
        brand_id: this.checkItem.brand,
      };
      base
        .getCategoryList(data)
        .then((res) => {
          console.log(res);
          this.largectgry = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMiddleCategory() {
      const data = {
        top_ctgry: this.checkItem.large_ctgry,
        brand: this.checkItem.brand,
      };
      base
        .getCategoryHierarchy(data)
        .then((res) => {
          console.log(res);
          this.middlectgry = res.data;
          console.log(this.middlectgry);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSmallCategory() {
      const data = {
        top_ctgry: this.checkItem.middle_ctgry,
        brand: this.checkItem.brand,
      };
      base
        .getCategoryHierarchy(data)
        .then((res) => {
          console.log(res);
          this.smallctgry = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
